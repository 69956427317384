import fetch from "../utils/fetch";

// 登录
export function login(data) {
  return fetch({
    url: '/api/mobile/user/login',
    method: 'Post',
    data: data
  });
}
// 注册
export function register(data) {
  return fetch({
    url: '/api/mobile/user/register',
    method: 'Post',
    data: data
  });
}
